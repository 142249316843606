<template>
  <NuxtLink
    :to="{
      name: 'machine-slug',
      params: {
        type: machine.type.key,
        brand: machine.brand.key,
        slug: machine.slug,
      },
    }"
    class="group"
  >
    <div
      class="flex flex-col items-center justify-between w-full rounded-lg bg-white transition-all text-center h-full"
      :class="{
        'max-w-[180px] lg:max-w-none flex-1 lg:w-auto overflow-hidden rounded-lg shadow lg:shadow-none p-4':
          isEtalageItem,
      }"
    >
      <div
        aria-hidden="true"
        class="aspect-[10/9] relative flex flex-col justify-center w-[140px] md:w-full"
        :class="{
          'md:max-w-[140px]': isEtalageItem,
          'md:max-w-[200px]': !isEtalageItem,
        }"
      >
        <NuxtImg
          :src="useStrapiImage(machine.list_image, imageSize)"
          class="absolute group-hover:scale-105 group-focus:scale-105 transition-all w-full flex justify-center"
          :alt="machine.name"
          :loading="loadImgEager || isEtalageItem ? 'eager' : 'lazy'"
        />
      </div>

      <div
        :class="{
          'flex flex-col items-start w-full sm:px-4 text-left': !isEtalageItem,
          'px-1.5 lg:px-0': isEtalageItem,
        }"
      >
        <div
          class="mt-4 mb-1 font-semibold text-xs group-hover:underline"
          :class="{
            'md:text-sm': isEtalageItem,
            'md:text-base': !isEtalageItem,
          }"
        >
          {{ machine.name }}
        </div>
        <p
          v-if="!isEtalageItem"
          class="line-clamp-2 text-left text-sm text-gray-700 mb-1.5"
        >
          {{ machine.description }}
        </p>
        <div
          aria-hidden="true"
          class="text-xs lg:text-sm mt-1"
          :class="{
            'text-los-blue-darkest': isEtalageItem,
            'text-los-pink-darkest': !isEtalageItem,
          }"
        >
          <span v-if="isEtalageItem && showPricePrefix">nu voor </span>
          <span
            v-if="!isEtalageItem && machine.old_price"
            class="line-through mr-2 text-los-darkest-gray text-xs lg:text-base font-medium"
            >{{ formatPrice(machine.old_price) }}</span
          >
          <span class="text-base lg:text-xl font-bold inline-block">{{
            formatPrice(machine.price)
          }}</span>
        </div>
        <span class="sr-only">Beschikbaar voor € {{ machinePrice }}</span>
      </div>
      <div v-if="isEtalageItem && !hideButton" class="hidden lg:block px-5">
        <div class="btn-main mt-6">Bekijk hier</div>
      </div>
      <div v-else></div>
      <!-- <div v-else class="flex flex-wrap items-center w-full mt-4">
        <div
          class="bg-gray-200 px-1 py-0.5 rounded-md text-[.65rem] lg:text-xs"
        >
          6 naalden
        </div>
      </div> -->
    </div>
  </NuxtLink>
</template>

<script setup>
const props = defineProps({
  machine: {
    type: Object,
    required: true,
  },
  isEtalageItem: {
    type: Boolean,
    default: false,
  },
  imageSize: {
    type: String,
    default: 'thumbnail',
  },
  loadImgEager: {
    type: Boolean,
    default: false,
  },
  hideButton: {
    type: Boolean,
    default: false,
  },
  showPricePrefix: {
    type: Boolean,
    default: true,
  },
});

const machinePrice = computed(() => {
  return `€ ${props.machine.price.toLocaleString('nl')},-`;
});

function formatPrice(price) {
  return `€ ${price.toLocaleString('nl')},-`;
}
</script>

<style lang="scss" scoped></style>
